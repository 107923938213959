<template>
  <div class="world backgroundWhite">
    <div class="content">
      <el-form ref="form" :model="form" label-width="80px" class="form" :rules="rules">
        <el-form-item label="需求类型" prop="needsType">
          <el-radio-group v-model="form.needsType" v-if="userType=='2'">
            <el-radio-button
              v-for="(item,index) in needTypeList"
              :label="item.value"
              :key="index"
            >{{item.label}}</el-radio-button>
          </el-radio-group>
          <el-radio-group v-model="form.needsType" v-else>
            <el-radio-button
              v-for="(item,index) in needDisList"
              :label="item.value"
              :key="index"
            >{{item.label}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="期望时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.expectTime"
            class="width100"
            value-format="yyyy/MM/dd"
            popper-class="eagle-a11y-uncut"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="需求内容">
          <Editor placeholder="请输入内容" @html="text" class="width100" v-model="form.needsContent"></Editor>
        </el-form-item>
        <el-form-item class="buttons text_C">
          <el-button class="w150 marR20" @click="close">取消</el-button>
          <span class="marL20"></span>
          <el-button type="primary" class="w150" @click="save('form')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Editor from "components/public/editor";
export default {
  name: "demeandInput",

  components: { Editor },
  data() {
    return {
      content: "",
      userType: "",

      form: {
        expectTime: "",
        needsContent: "",
        needsType: "",
      },
      needTypeList: [
        { value: "雇主培训", label: "雇主培训" },
        { value: "招聘人才", label: "招聘人才" },
      ],
      needDisList: [
        { value: "机构培训", label: "机构培训" },
        { value: "课件学习", label: "课件学习" },
        { value: "创业", label: "创业" },
        { value: "就业", label: "就业" },
      ],
      rules: {
        needsType: [
          {
            required: true,
            message: "请选择需求类型",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    text(val) {
      this.form.needsContent = val;
    },
    save(form) {
      if (!this.form.needsType) {
        this.$message.error("请选择需求类型");
        return;
      }
      if (!this.form.expectTime) {
        this.$message.error("请选择期望时间");
        return;
      }
      if (!this.form.needsContent) {
        this.$message.error("请选择需求内容");
        return;
      }
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let res = await this.$api.addNeed(this.form);
          if (res.data.success) {
          this.$message.success('需求添加成功')
            this.close(0);
            this.$parent.$parent.needsList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
  },
  created() {
    if (this.$store.state.login) {
      let userType = this.$store.state.login.userInfo.userType;
      this.userType = userType;
    }
  },
};
</script>

<style scoped lang="less">
.child_content .title h1 {
  transform: translateY(-10px);
}
</style>
