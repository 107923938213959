<template>
  <div class="world offline businessNeeds">
    <div class="title backgroundWhite marB10 flex wrapper">
      <div class="left">
        <h1 class="h1">我的需求</h1>
      </div>
      <div class="right text_r">
        <el-button
          type="primary"
          class="bs_btn_oo w100 borderRadius0"
          size="mini"
          @click="opens('我的培训需求', 'input')"
          >提出需求</el-button
        >
      </div>
    </div>
    <div class="offline_content">
      <div class="headers">
        <div class="tab_content">
          <div class="input backgroundWhite marB10 flex">
            <div class="left">
              <span>筛选：</span>
              <el-select aria-label="下拉列表框"
                v-if="userType == '1'"
                v-model="queryParam.needsType"
                @change="selectNeedsType"
                placeholder="全部类型"
                size="mini"
                class="select_input img w150 marR20"
                clearable
              >
                <el-option
                  v-for="item in needDisList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >{{ item.label }}</el-option
                >
              </el-select>
              <el-select aria-label="下拉列表框"
                v-else
                v-model="queryParam.needsType"
                @change="selectNeedsType"
                placeholder="全部类型"
                size="mini"
                class="select_input img w150 marR20"
                clearable
              >
                <el-option
                  v-for="item in needTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >{{ item.label }}</el-option
                >
              </el-select>
              <el-select aria-label="下拉列表框"
                v-model="queryParam.needsStatus"
                @change="selectneedsStatus"
                placeholder="全部状态"
                size="mini"
                class="select_input img w150 marR20"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="待处理" value="1"></el-option>
                <el-option label="已处理" value="2"></el-option>
              </el-select>
            </div>
          </div>
          <div
            class="tables loads"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
          >
            <el-table :data="tableData" style="width: 100%" stripe>
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form
                    label-position="left"
                    inline
                    class="demo-table-expand"
                  >
                    <el-form-item label="提交时间：">
                      <span>{{ props.row.createTime }}</span>
                    </el-form-item>
                    <el-form-item label="期望时间：">
                      <span>{{ props.row.expectTime }}</span>
                    </el-form-item>
                    <el-form-item label="需求概要：" class="width100">
                      <span v-html="props.row.needsContent"></span>
                    </el-form-item>
                    <el-form-item label="反馈时间：">
                      <span>{{ props.row.updateTime }}</span>
                    </el-form-item>
                    <el-form-item
                      label="反馈内容："
                      v-if="props.row.feeds[0]"
                      class="width100"
                    >
                      <span v-html="props.row.feeds[0].needFeedback"></span>
                    </el-form-item>
                    <el-form-item label="反馈单位：" v-if="props.row.feeds[0]">
                      <span>{{ props.row.feeds[0].departName }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column
                prop="needsType"
                align="center"
                label="需求类型"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                align="center"
                width="180"
                label="提出时间"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="expectTime"
                label="期望时间"
              ></el-table-column>
              <el-table-column
                prop="needsStatus"
                align="center"
                label="需求状态"
              ></el-table-column>
              <el-table-column
                align="center"
                width="200"
                label="操作"
                prop="needsStatus"
              >
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="getDelateById(scope.row.id)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                class="work_pagination"
                :current-page.sync="queryParam.pageNum"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="queryParam.pageSize"
                layout="total,prev, pager, next, jumper"
                :prev-text="pagination.pre"
                :next-text="pagination.next"
                :total="total"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <el-dialog
      title="提出需求"
      :visible.sync="dialogVisible"
      :width="width"
      :before-close="close"
    >
      <div>
        <Needs @close="close"></Needs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Needs from "@/components/enterprise/Needs";
export default {
  name: "businessNeeds",
  components: {
    Needs,
  },
  data() {
    return {
      loading: false,
      comInfo: {},
      dialogVisible: false,
      type: "",
      needList: [],
      viewNeedsDatail: {},
      tabs: ["我参加的", "我收藏的"],
      selectValue: "",
      input: "",
      width: "590px;",
      title: "",
      needTypeList: [
        { value: "雇主培训", label: "雇主培训" },
        { value: "招聘人才", label: "招聘人才" },
      ],
      needDisList: [
        { value: "机构培训", label: "机构培训" },
        { value: "课件学习", label: "课件学习" },
        { value: "创业", label: "创业" },
        { value: "就业", label: "就业" },
      ],
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 6,
        needsType: "",
        needsStatus: "",
      },
      tableData: [],

      pagination: {
        pre: "上一页",
        next: "下一页",
      },
    };
  },
  watch: {
    // dialogVisible: "refresh"
  },
  methods: {
    async needsList() {
      // this.loading = true;
      let res = await this.$api.needList(this.queryParam);
      if (res.data.success) {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      }
      this.loading = false;
    },

    // 删除当前信息项
    getDelateById(id) {
      this.id = id;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$api.updateNeedById({
            id: id,
          });
          if (res.data.success) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.needsList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询
    selectNeedsType(value) {
      this.queryParam.needsType = value;
      this.queryParam.pageNum = 1;
      this.needsList();
    },
    selectneedsStatus(value) {
      this.queryParam.needsStatus = value;
      this.queryParam.pageNum = 1;
      this.needsList();
    },
    refresh() {
      if (!this.dialogVisible) {
        this.needsList();
      }
    },
    close(val) {
      this.dialogVisible = false;
    },
    // 分页
    handleCurrentChange(val) {
      this.queryParam.pageNum = val;
      this.needsList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.needsList();
    },
    opens(title, type) {
      this.title = title;
      this.width = "590px;";
      this.type = type;
      this.dialogVisible = true;
    },

    Close() {
      this.dialogVisible = false;
    },
  },
  created() {
    this.needsList();
    if (this.$store.state.login) {
      let userType = this.$store.state.login.userInfo.userType;
      this.userType = userType;
    }
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/needs.less";
.content{
  background: #ffffff;
}
.world {
  padding: 0px 10px;
  margin: 0px;
  .title {
    // margin-top: 10px;
    //padding-top: 10px;
  }
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 55%;
}
.tables {
  background: #ffffff;
  padding: 20px;
  .el-table {
    margin-bottom: 20px;
  }
}
/deep/ .el-loading-spinner {
  top: 20%;
}
</style>
